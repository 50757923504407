<template>
  <v-container>
    <v-card class="m-light-card">
      <course-details-form-p
        v-model="courseData.courseDetails"
        should-show-course-material-alert
        :valid.sync="valid"
        :isClickSaveButton="saveButtonClick"
        :read-only="hideBtn"
      ></course-details-form-p>
      <v-card-actions v-if="!hideBtn">
        <v-row no-gutters justify="end">
          <m-rounded-button
            outlined
            color="red accent-3"
            data-qa="cancel-button"
            class="btn-outlined"
            @click="cancel"
          >
            <v-icon left>cancel</v-icon>
            {{ !$language ? "Cancel" : "বাতিল করুন" }}
          </m-rounded-button>
          <m-rounded-button
            depressed
            :loading="updating"
            class="ml-2 btn-outlined"
            @click="click(), save()"
          >
            <m-icon left>save_alt</m-icon>
            {{ !$language ? "Save" : "সেভ করুন" }}
          </m-rounded-button>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import CourseDetailsFormP from "#ecf/course/components/CourseDetailsFormP";

export default {
  name: "CourseFormP",
  components: { CourseDetailsFormP },
  props: {
    hideBtn: {
      type: Boolean,
      default: false
    },
    course: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      currentStep: 0,
      updating: false,
      valid: true,
      saveButtonClick: false,
      courseData: {},
      reroute: false
    };
  },
  created() {
    this.courseData = { courseDetails: { ...this.course }, batches: [] };
    if (!("course_materials" in this.courseData.courseDetails)) {
      this.$set(this.courseData.courseDetails, "course_materials", {
        video_lectures: 0,
        live_classes: 0,
        quizzes: 0,
        lecture_notes: 0,
        projects: 0,
        assignments: 0,
        exams: 0
      });
    }
  },
  methods: {
    nextStep() {
      if (this.currentStep !== 1) {
        this.currentStep++;
      }
    },
    previousStep() {
      if (this.currentStep !== 0) {
        this.currentStep--;
      }
    },
    async cancel() {
      await this.$router.go(-1);
      this.saveButtonClick = false;
    },
    click(){
      this.saveButtonClick = true;
    },
    async save() {
      
      try {
        this.updating = true;
        if (this.courseData.courseDetails.category === "Others")
          this.courseData.courseDetails.subcategories = [
            this.courseData.courseDetails.additionalSubCategory
          ];
        if (!("image" in this.courseData.courseDetails)) {
          // delete this.courseData.courseDetails.image;
          this.$delete(this.courseData.courseDetails, "image");
        } else {
          this.courseData.courseDetails.image.imageURL += ".png";
        }
        if (
          "about_video" in this.courseData.courseDetails &&
          this.courseData.courseDetails.about_video == ""
        ) {
          delete this.courseData.courseDetails.about_video;
        }
        if (
          "facebook_group" in this.courseData.courseDetails &&
          this.courseData.courseDetails.facebook_group == ""
        ) {
          delete this.courseData.courseDetails.facebook_group;
        }
        for (var key in this.courseData.courseDetails.course_materials) {
          if (this.courseData.courseDetails.course_materials[key] === 0) {
            this.courseData.courseDetails.course_materials[key] = "00";
          } else {
            this.courseData.courseDetails.course_materials[key] = String(
              this.courseData.courseDetails.course_materials[key]
            ).padStart(2, 0);
          }
        }
        await this.$store.dispatch("course/update", {
          partner_code: this.courseData.courseDetails.partner_code,
          course_id: this.courseData.courseDetails.course_id,
          course_data: this.courseData.courseDetails
        });
        this.reroute = true;
      } catch (e) {
        let validationErrors = Object.entries(
          JSON.parse(JSON.stringify(e.response.data.error))
        ).map(x => `${x[0]} ${x[1][0].slice(0, -1).toLowerCase()}`);

        if(validationErrors !== undefined) this.$root.$emit("alert", [undefined, validationErrors.join(", ")]);
        else this.$root.$emit('alert', [undefined, e.message])
      } finally {
        this.updating = false;
        if (this.reroute) await this.$router.go(-1);
      }
    }
  }
};
</script>
