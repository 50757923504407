var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("page-title", {
        staticClass: "mt-5",
        attrs: {
          "previous-location": "",
          "page-title": !_vm.$language ? "Edit Course" : "কোর্স এডিট করুন",
        },
      }),
      _c("course-form-p", {
        attrs: { "hide-btn": _vm.notOwner, course: _vm.course },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }