<template>
  <v-container>
    <page-title
      previous-location
      :page-title="!$language ? 'Edit Course' : 'কোর্স এডিট করুন'"
      class="mt-5"
    ></page-title>
    <course-form-p :hide-btn="notOwner" :course="course"></course-form-p>
  </v-container>
</template>

<script>
import _ from "lodash";
import { required } from "@ecf/global/validators";
import { FieldValidations } from "/global/utils/validations.js";
import CourseFormP from "#ecf/course/components/CourseFormP";
export default {
  name: "CourseEditPage",
  components: { CourseFormP },
  mixins: [FieldValidations],
  props: {
    saving: Boolean,
    course: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formData: {
        course_title: undefined,
        course_description: undefined,
        course_key: undefined
      },
      rules: { required: [
        this.required
      ] }
    };
  },
  computed: {
    notOwner() {
      return this.course.owner_id != this.$store.state.user.user.user_id;
    }
  }
};
</script>
