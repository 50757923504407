function required(fieldName = "The field") {
  return v => !!v || `${fieldName} is required`;
}
function email() {
  return v => /.+@.+/.test(v) || "E-mail must be valid";
}

function maxLength(fieldName = "This field", length = 12) {
  return v =>
    (v && v.length <= length) ||
    `${fieldName} must be less than or equal ${length} characters`;
}

function minLength(fieldName = "This field", length = 8) {
  return v =>
    (v && v.length >= length) ||
    `${fieldName} must be greater than or equal ${length} characters`;
}

function onlyNumber(fieldName = "This field") {
  return v => /[0-9]+/.test(v) || `${fieldName} can only have digits.`;
}

export function getValidators(...validators) {
  return validators.map(validatorName => {
    if (this[validatorName]) {
      return this[validatorName];
    } else {
      throw Error(`Validator ${validatorName} not found`);
    }
  });
}

export { required, email, maxLength, minLength, onlyNumber };
